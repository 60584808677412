<template>
  <div id="components-back-top-custom">
    <a-tooltip>
      <template #title>回到顶部</template>
      <a-back-top>
        <a-button type="primary" shape="circle">顶</a-button>
      </a-back-top>
    </a-tooltip>
  </div>

  <a-card>
    <a-form ref="formRef" :model="form" :rules="rules" scrollToFirstError hideRequiredMark>
      <table class="table">
        <thead>
          <tr>
            <th colspan="24">
              <a-typography-title :level="2">小程序订单信息修改</a-typography-title>
              <a-typography-title :level="3">金额相关只能修改一次，请和客户确定订单所有事情后提交修改信息</a-typography-title>
            </th>
          </tr>
          <tr>

            <th class="left-td" colspan="4">
              来源：{{ insideName }}
            </th>
            <th colspan="4">
              是否使用优惠券：{{ orderVehiclesCouponAmtSum > 0 ? '是' : '否' }}
            </th>
            <th v-if="orderVehiclesCouponAmtSum > 0" colspan="4">
              优惠券金额：{{ orderVehiclesCouponAmtSum }}
            </th>

          </tr>
          <tr>
            <th class="left-td" colspan="10">
              <a-form-item label="承运公司" :wrapperCol="{ flex: 'auto' }" :name="['order', 'carrierType', 'label']">
                <a-select v-model:value="form.order.carrierType.label" style="width: 300px" placeholder="请选择承运商"
                  :bordered="false" @change="carrierChange">
                  <a-select-option v-for="item in carrierList" :key="item.id"  :value="item.label">{{ item.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </th>
            <th class="center" colspan="6">
              <!-- :name="['order','sourceMan']" -->
              <a-form-item label="分单员" style="width: 200px" :wrapperCol="{ flex: 'auto' }">
                <a-select v-model:value="form.order.sourceMan" placeholder="请选择分单员" :bordered="false"
                  @change="sourceManChange">
                  <a-select-option v-for="item in empList" :key="item.empId" :value="item.empName">{{ item.empName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </th>
            <th class="right-td" colspan="6">录单员：{{ form.order.salesman }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!--  客户 -->
            <td rowspan="3" colspan="2" width="6%">
              <strong>客户</strong>
              <a-button type="link" @click="chooseCustomerVisible = true">选择</a-button>
            </td>
            <td class="label-td" colspan="2" width="5%">姓名</td>
            <td colspan="4" width="22%">
              {{ form.orderCustomer.customerName }}
            </td>
            <!-- 对接人 -->
            <td rowspan="3" colspan="2" width="6%">
              <strong>交车人</strong>
              <a-button type="link" @click="copyConsignor(0)">同步</a-button>
            </td>
            <td class="label-td" colspan="2" width="6%">姓名</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'consignor', 'name']">
                <a-input v-model:value="form.order.consignor.name" autocomplete="off" allowClear />
              </a-form-item>
            </td>
            <!-- 取车人 -->
            <td rowspan="3" colspan="2" width="6%">
              <strong>取车人</strong>
              <a-button type="link" @click="copyConsignor(1)">同步</a-button>
            </td>
            <td class="label-td" colspan="2" width="6%">姓名</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'picker', 'name']">
                <a-input v-model:value="form.order.picker.name" autocomplete="off" allowClear />
              </a-form-item>
            </td>
          </tr>
          <tr>
            <!--  客户 -->
            <td class="label-td" colspan="2" width="5%">证件号</td>
            <td colspan="4" width="22%">
              {{ form.orderCustomer.certificateNumber }}
            </td>
            <!-- 对接人 -->
            <td class="label-td" colspan="2" width="6%">证件号</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'consignor', 'certificateNumber']">
                <a-input v-model:value="form.order.consignor.certificateNumber" autocomplete="off" allowClear />
              </a-form-item>
            </td>
            <!-- 取车人 -->
            <td class="label-td" colspan="2" width="6%">证件号</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'picker', 'certificateNumber']">
                <a-input v-model:value="form.order.picker.certificateNumber" autocomplete="off" allowClear />
              </a-form-item>
            </td>
          </tr>
          <tr>
            <!--  客户 -->
            <td class="label-td" colspan="2" width="5%">电话</td>
            <td colspan="4" width="22%">{{ form.orderCustomer.customerMobile }}</td>
            <!-- 对接人 -->
            <td class="label-td" colspan="2" width="6%">电话</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'consignor', 'mobile']">
                <a-input v-model:value="form.order.consignor.mobile" autocomplete="off" allowClear />
              </a-form-item>
            </td>
            <!-- 取车人 -->
            <td class="label-td" colspan="2" width="6%">电话</td>
            <td colspan="4" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'picker', 'mobile']">
                <a-input v-model:value="form.order.picker.mobile" autocomplete="off" allowClear />
              </a-form-item>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2">对接人</td>
            <td colspan="19" class="left-td">
              <a-select style="width:300px" v-model:value="form.orderCustomer.businessContactPerson" placeholder="对接人">
                <a-select-option v-for="item in receiverList" @click="chooseContact(item)" :key="item.receiver">{{ item.receiver
                }}</a-select-option>
              </a-select>
              &emsp;
              <span
                v-show="form.orderCustomer.businessContactMobile">对接人电话：{{
                  form.orderCustomer.businessContactMobile }}</span>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2" width="6%">起运地</td>
            <td colspan="6" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" name="startAddress">
                <a-cascader v-model:value="form.startAddress" :options="$store.state.app.cityDict"
                  :show-search="{ filter }" @change="setStartAddress" />
              </a-form-item>
            </td>
            <td class="label-td" colspan="2" width="6%">目的地</td>
            <td colspan="6" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" name="endAddress">
                <a-cascader v-model:value="form.endAddress" :options="$store.state.app.cityDict" :show-search="{ filter }"
                  @change="setEndAddress" placeholder="请选择目的地" />
              </a-form-item>
            </td>
            <td class="label-td" colspan="2" width="6%">所属区域</td>
            <td colspan="6" width="22%">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'officeOrgId']">
                <a-select v-model:value="form.order.officeOrgId" allowClear>
                  <a-select-option v-for="item in orgs" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2" width="6%">业务类型</td>
            <td colspan="6">
              <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'orderFund', 'value']">
                <a-select v-model:value="form.order.orderFund.value" allowClear>
                  <a-select-option :value="1">普通</a-select-option>
                  <a-select-option :value="2">垫资</a-select-option>
                </a-select>
              </a-form-item>
            </td>
            <td class="label-td" colspan="2" width="6%">结算方式</td>
            <td colspan="6">
              <a-row :gutter="8">
                <a-col :span="8">
                  <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'orderSettlement', 'value']">
                    <a-select v-model:value="form.order.orderSettlement.value" allowClear>
                      <a-select-option :value="1">货到付款</a-select-option>
                      <a-select-option :value="2">起运地付清</a-select-option>
                      <a-select-option :value="3">预付X到付X</a-select-option>
                      <a-select-option :value="5">记账</a-select-option>
                      <a-select-option :value="4"
                        :disabled="form.orderCustomer.settlementMode.value !== 2">月结</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="16" v-if="form.order.orderSettlement.value === 3">
                  <a-form-item label="预付（元）" :name="['order', 'advanceCharge']">
                    <a-input v-model:value="form.order.advanceCharge" prefix="￥" />
                  </a-form-item>
                </a-col>
                <a-col :span="16" v-if="form.order.orderSettlement.value === 5">
                  <a-form-item label="记账期限" :name="['order', 'deadline']">
                    <a-input-number v-model:value="form.order.deadline" />
                  </a-form-item>
                </a-col>
              </a-row>
            </td>
            <td class="label-td" colspan="2" width="6%">产品服务</td>
            <td colspan="6">
              <a-row :gutter="8">
                <a-col :span="8">
                  <a-form-item :wrapperCol="{ flex: 'auto' }">
                    <a-select v-model:value="form.order.productId">
                      <a-select-option :value="null">无产品</a-select-option>
                      <a-select-option v-for="item in products" :key="item.value">{{ item.label }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="16" v-if="form.order.productId === '1392734185333278722'">
                  <a-form-item label="时效（天）" :name="['order', 'aging']">
                    <a-input v-model:value="form.order.aging" />
                  </a-form-item>
                </a-col>
              </a-row>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2">发票</td>
            <td colspan="6" class="left-td">
              <a-form-item :wrapperCol="{ flex: 'auto' }">
                <a-switch v-model:checked="form.order.isInvoice.value" :checkedValue="1" :unCheckedValue="0" />
                <span v-if="form.order.isInvoice.value" class="m-l4">{{form.order.carrierType.label}}</span> 
              </a-form-item>
             
            </td>
            <td colspan="22" v-if="!form.order.isInvoice.value">
            </td>
            <template v-else>
              <td class="label-td" colspan="2">税率</td>
              <td  colspan="6" >
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'taxRate','value']"> 
                <a-select @change="taxChange" v-model:value="form.order.taxRate.value" placeholder="选择税率" allowClear>
                  <a-select-option v-for=" item in taxRateList" :key="item.id" :lable="item.taxItem" :value="item.label">{{ item.label }}</a-select-option>
                </a-select>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2">税目</td>
              <td  colspan="6" >
                   <div>{{ taxItem }}</div>
              </td>
            </template> 
          </tr>
          <tr>
            <td class="label-td" colspan="2">返款</td>
            <td colspan="2" class="left-td">
              <a-form-item :wrapperCol="{ flex: 'auto' }">
                <a-switch v-model:checked="form.order.isRefund.value" :checkedValue="1" :unCheckedValue="0" />
              </a-form-item>
            </td>
            <template v-if="form.order.isRefund.value">
              <td class="label-td" colspan="2" width="6%">返款金额</td>
              <td colspan="2">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['order', 'refundFee']">
                  <a-input v-model:value="form.order.refundFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="6%">返款人</td>
              <td colspan="6">
                <a-form-item :wrapperCol="{ flex: 'auto' }" name="refunder">
                  <a-select v-model:value="form.order.refunder" allowClear @change="refunderChange" show-search>
                    <a-select-option v-for="item in receiverList" :key="item.receiver" :value="item.receiverMobile">{{ item.receiver
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
                <!-- <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','refunder']">
                <a-input v-model:value="form.order.refunder" />
              </a-form-item> -->
              </td>
              <td class="label-td" colspan="2" width="6%">返款电话</td>
              <td colspan="6">
                <!-- <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','refunderMobile']">
                <a-input v-model:value="form.order.refunderMobile" />
              </a-form-item> -->
                {{ form.order.refunderMobile }}
              </td>
            </template>
            <td v-else colspan="20" />
          </tr>
          <!--          <tr>-->
          <!--            <td class="label-td" colspan="2">同行代收</td>-->
          <!--            <td colspan="2" class="left-td">-->
          <!--              <a-form-item :wrapperCol="{flex:'auto'}">-->
          <!--                <a-switch :defaultChecked="!!form.order.isPeerCollection.value" @change="checked => form.order.isPeerCollection = { value:checked ? 1:0}" />-->
          <!--              </a-form-item>-->
          <!--            </td>-->
          <!--            <template v-if="form.order.isPeerCollection.value">-->
          <!--              <td class="label-td" colspan="2" width="6%">代收金额</td>-->
          <!--              <td colspan="2">-->
          <!--                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','peerCollectionFee']">-->
          <!--                  <a-input v-model:value="form.order.peerCollectionFee" prefix="￥" />-->
          <!--                </a-form-item>-->
          <!--              </td>-->
          <!--            </template>-->
          <!--            <td v-else colspan="20" />-->
          <!--          </tr>-->
          <tr>
            <td colspan="2" width="6%" class="label-td">备注</td>
            <td colspan="22">
              <a-textarea v-model:value="form.order.remark" :rows="3" :maxlength="100" showCount />
            </td>
          </tr>
          <tr>
            <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">等通知放车</span></td>
            <td colspan="4">
              <div style="display:flex;justify-content: left;align-items:center">
                <a-radio-group v-model:value="form.order.isNoticeReleaseCar.value" @change="isNoticeReleaseCarChange">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </div>
            </td>
            <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">交车条</span></td>
            <td colspan="4">
              <div style="display:flex;justify-content: left;align-items:center">
                <a-radio-group v-model:value="form.order.isDeliveryCard.value">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </div>
            </td>
            <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">验车打款</span></td>
            <td colspan="4">
              <div style="display:flex;justify-content: left;align-items:center">
                <a-radio-group v-model:value="form.order.isInspectionPay.value" @change="isInspectionPayChange">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
                <div class="isInspectionPay-div" v-show="form.order.isInspectionPay?.value === 1" style="flex:1">
                  <a-popconfirm ok-text="确认" cancel-text="">
                    <template #title>
                      <div style="width:250px">
                        <div>备注填写</div>
                        <a-textarea v-model:value="form.order.inspectPayRemark" showCount :maxlength="100" :rows="3"
                          placeholder="备注" />
                      </div>
                    </template>
                    <a>备注填写</a>
                  </a-popconfirm>
                </div>
              </div>
            </td>
            <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">通知业务员</span></td>
            <td colspan="2">
              <div style="display:flex;justify-content: left;align-items:center">
                <a-radio-group v-model:value="form.order.isNoticeSalesman.value" >
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="position: relative;">
        <a-button type="primary" size="small" class="batch-data" @click="batchGiveData">批量赋值</a-button>
        <template v-for="(item, index) in  form.orderVehicles" :key="index">
          <table class="table" @click="indexNum = index">
            <tr class="vehicle-div">
              <td class="label-td" rowspan="7" width="6%">
                <strong>车辆{{ index + 1 }}</strong>
                <a-button type="link" @click="copyVehicle(index)">复加</a-button>
                <a-button type="link" v-if="form.orderVehicles.length > 1" @click="deleteVehicle(index)">删除</a-button>
              </td>
              <td class="label-td" colspan="2" width="8%">车型</td>
              <td colspan="4" width="20%">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['orderVehicles', index, 'brands']"
                  :rules="[{ required: true, message: '请选择车型', trigger: 'change', type: 'array' }]">
                  <div style="display:flex">
                    <a-cascader v-model:value="item.brands" :options="carBrands" :show-search="{ filter }"
                      @change="(value) => { [form.orderVehicles[index].brandId, form.orderVehicles[index].modelId] = value }" />
                    <a-select v-model:value="item.vehicleType.value" style="width: 100px" placeholder="请选择类型">
                      <a-select-option :value="1">新车</a-select-option>
                      <a-select-option :value="2">二手车</a-select-option>
                    </a-select>
                  </div>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">车架号</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['orderVehicles', index, 'vinNo']"
                  :rules="[{ required: true, message: '请填写车架号', trigger: 'blur' }, { min: 6, max: 20, message: '车架号长度为6-20位', trigger: 'blur' }, { validator: vinNoRepeat, message: '车架号重复', trigger: 'blur' }]">
                  <a-input v-model:value="item.vinNo" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">车辆状况</td>
              <td colspan="4" class="left-td" width="14%">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['orderVehicles', index, 'status', 'value']"
                  :rules="[{ required: true, message: '请选择', trigger: 'change', type: 'number' }]">
                  <a-select v-model:value="item.status.value" allowClear>
                    <a-select-option :value="1">正常</a-select-option>
                    <a-select-option :value="2">开不动</a-select-option>
                    <a-select-option :value="3">事故车</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">运费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['orderVehicles', index, 'freight']"
                  :rules="[{ required: true, message: '请填写运费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.freight" prefix="￥" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="6%">保险</td>
              <td colspan="4" width="20%">
                <a-form-item :name="['orderVehicles', index, 'insuranceCompany', 'value']"
                  :rules="[{ required: true, message: '请选择保险购买公司', trigger: 'change', type: 'number' }]">
                  <a-radio-group v-model:value="item.insuranceCompany.value">
                  <div class="flex">
                    <a-radio :value="1">否</a-radio>
                    <a-radio :value="2" >太平洋（江）</a-radio>
                    <a-radio :value="7">财险</a-radio>
                    <a-radio :value="6">国际</a-radio>
                  </div>
                  </a-radio-group>
                </a-form-item>
              </td>
              <td class="label-td" colspan="6" width="22%">
                <!-- {{item.insuranceCompany.value === 2 ?'太平洋':item.insuranceCompany.value === 3 ? '锦泰':item.insuranceCompany.value === 4 ? '平安':''}} -->
                {{ item.insuranceCompany.value === 2 ? '太平洋（江）' : item.insuranceCompany.value === 7 ? '财险' : item.insuranceCompany.value==6?'国际':'' }}
              </td>
              <td class="label-td" colspan="2" width="8%">保额（万元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany.value > 1" :name="['orderVehicles', index, 'insuranceFee']"
                  :rules="[{ required: true, message: '请填写额度', trigger: 'blur' }, { pattern: number, message: '数字格式不正确及不能为0', trigger: 'blur' }]">
                  <a-input v-model:value="item.insuranceFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">保费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany.value > 1" :name="['orderVehicles', index, 'insurancePremium']"
                  :rules="item.insuranceCompany.value == 6?[{ required: false, message: '请填写额度', trigger: 'blur' }]:[{ required: true, message: '请填写额度', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.insurancePremium" prefix="￥" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">预约时间</td>
              <td class="label-td" colspan="2" width="8%">
                <a-date-picker show-time format="YYYY-MM-DD HH:mm" placeholder="选择时间" v-model:value="item.pickAppointTime"
                  @change="reservationTime" />
              </td>
              <td colspan="20"></td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">取车</td>
              <td colspan="2" width="8%" style="height:43px;">
                <div style="display:flex;align-items:center;justify-content: space-between;">
                  <a-switch v-if="pickTypes.length > 0" v-model:checked="item.isPlaceIn.value" :checkedValue="1"
                    :unCheckedValue="0" @change="pickChange" />
                  <a-select style="width:100px" v-if="item.isPlaceIn.value === 1" v-model:value="item.pickType.value"
                    allowClear placeholder="取车方式">
                    <!-- <a-select-option :value="1">大板车</a-select-option> -->
                    <a-select-option :value="2">小板车</a-select-option>
                    <a-select-option :value="3">地跑</a-select-option>
                  </a-select>
                </div>
              </td>
              <!-- 客户送车门店(反) -->
              <!-- <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">是否到达门店</td>
              <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">
                <a-radio-group v-model:value="item.isCusSend.value" @change="isCusSendChange(item)">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </td> -->
              <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">
              </td>
              <!-- <td v-show="item.isPlaceIn.value === 1" class="label-td" colspan="2" width="8%">预约取车时间</td>
              <td v-show="item.isPlaceIn.value === 1" class="label-td" colspan="2" width="8%" @click="indexNum = index">
                <a-date-picker show-time format="YYYY-MM-DD HH:mm" placeholder="选择时间" v-model:value="item.pickAppointTime"
                  @change="reservationTime" />
              </td> -->
              <td v-show="item.isPlaceIn.value === 1" class="label-td" colspan="2" width="8%">取车费（元）</td>
              <td v-show="item.isPlaceIn.value === 1" colspan="6" width="22%">
                <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['orderVehicles', index, 'placeInFee']"
                  :rules="isPlaceInCheck">
                  <a-input v-model:value="item.placeInFee" prefix="￥" />
                </a-form-item>
              </td>
              <td v-show="item.isPlaceIn.value === 1" class="label-td" colspan="2" width="8%">取车地址</td>
              <td v-show="item.isPlaceIn.value === 1" colspan="8" width="36%">
                <a-form-item v-if="item.isPlaceIn.value" :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.placeInAddress" />
                </a-form-item>
              </td>
              <td v-show="item.isPlaceIn?.value === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isPlaceIn?.value === 0" class="label-td" colspan="2" width="8%">
              </td>
              <td v-show="item.isPlaceIn?.value === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isPlaceIn?.value === 0" class="label-td" :colspan="item.isPlaceIn?.value === 1 ? 5 : 12"
                width="8%" style="padding:5px 2px"></td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">送车</td>
              <td colspan="2" style="height:43px">
                <div style="display:flex;align-items:center;justify-content: space-between;">
                  <a-switch v-model:checked="item.isTakeOut.value" :checkedValue="1" :unCheckedValue="0"
                    @change="takeChange" />
                  <a-select v-if="item.isTakeOut.value === 1" style="width:100px" v-model:value="item.takeType.value"
                    allowClear placeholder="送车方式">
                    <!-- <a-select-option :value="1">大板车</a-select-option> -->
                    <a-select-option :value="2">小板车</a-select-option>
                    <a-select-option :value="3">地跑</a-select-option>
                  </a-select>
                </div>
              </td>
              <!-- <td class="label-td" colspan="2" width="8%">送车方式</td>
              <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-if="item.isTakeOut.value === 1" class="label-td" colspan="2" width="8%">
                <a-select style="width:120px" v-model:value="item.takeType.value" allowClear>
                  <a-select-option :value="1">大板车</a-select-option>
                  <a-select-option :value="2">小板车</a-select-option>
                  <a-select-option :value="3">地跑</a-select-option>
                </a-select>
              </td> -->
              <!-- 客户取车门店(反) -->
              <!-- <td v-show="item.isTakeOut?.value === 0" class="label-td" colspan="2" width="8%">是否到达门店</td>
              <td v-show="item.isTakeOut?.value === 0" class="label-td" colspan="2" width="8%">
                <a-radio-group v-model:value="item.isCusPick.value" @change="isCusPickChange(item)">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </td> -->
              <td v-show="item.isTakeOut?.value === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isTakeOut?.value === 0" class="label-td" colspan="2" width="8%">
              </td>
              <td v-show="item.isTakeOut?.value === 0" class="label-td" colspan="2" width="8%"></td>
              <td v-show="item.isTakeOut?.value === 0" class="label-td" :colspan="item.isTakeOut?.value === 1 ? 5 : 12"
                width="8%" style="padding:5px 2px"></td>
              <td class="label-td" colspan="2" width="12%" v-show="item.isTakeOut.value === 1">送车费（元）</td>
              <td colspan="6" width="22%" v-show="item.isTakeOut.value === 1">
                <a-form-item v-if="item.isTakeOut.value" :wrapperCol="{ flex: 'auto' }"
                  :name="['orderVehicles', index, 'takeOutFee']" :rules="isTakeOutCheck">
                  <a-input v-model:value="item.takeOutFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%" v-show="item.isTakeOut.value === 1">送车地址</td>
              <td colspan="8" width="36%" v-show="item.isTakeOut.value === 1">
                <a-form-item v-if="item.isTakeOut.value" :wrapperCol="{ flex: 'auto' }">
                  <a-input v-model:value="item.takeOutAddress" />
                </a-form-item>
              </td>
            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">备注</td>
              <td colspan="22">
                <a-textarea v-model:value="item.remark" :rows="2" :maxlength="100" showCount />
              </td>
            </tr>
          </table>
        </template>
      </div>
      <!-- <div style="text-align:center">
        <a-button type="link" @click="addVehicle">
          <PlusOutlined /> 添加
        </a-button>
      </div> -->
      <div class="save-button">
        <a-space>
          <a-button type="primary" :loading="subLoading" @click="save">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-card>
  <ChooseCustomer v-if="chooseCustomerVisible" v-model:visible="chooseCustomerVisible" @select="chooseCustomer" />
</template>
<script>
import { list as productList } from '@/api/trans/product'
import { list as orgList, cityOrg } from '@/api/system/organzition'
import { carBrandTree } from '@/api/system/setting'
import { detail, pushEdit, getCooper, appletModify,getSubjectList } from '@/api/trans/order'
import { carrierAddress } from '@/api/global'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, reactive, toRefs,createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
// import { PlusOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'

import ChooseCustomer from '@/views/components/chooseCustomer'
import { mobileValidate, moneyValidate, idNumberValidate, positiveNumberValidate, number } from '@/utils/validate'
export default {
  name: 'order-applet-edit',
  setup () {
    const store = useStore()
    const router = useRouter()
    const carrierList = ref([])//发票主体列表
    const taxRateList = ref([]) //税率列表
    const taxItem = ref('')
    const formRef = ref()
    const data = reactive({
      insideName: '',
      orderVehiclesCouponAmtSum: 0,
      indexNum: 0,
      value: '',
      subLoading: false,
      products: [],
      carBrands: [],
      orgs: [],
      empList: [],
      takeAddressList: [],
      pickAddressList: [],
      receiverList: [],
      type: 0,
      isPlaceInCheck: [{ required: true, message: '请填写取车费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
      isTakeOutCheck: [{ required: true, message: '请填写送车费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
      switchStatus: false,
      pickTypes: [],
      pickType: [
        {
          label: '大板车',
          value: 1
        },
        {
          label: '小板车',
          value: 2
        },
        {
          label: '地跑',
          value: 3
        }
      ],
      form: {
        order: {
          picker: {},
          consignor: {},
          carrierType: {},
          isInvoice: {},
          isRefund: { value: 0, label: '' },
          isPeerCollection: { value: 0, label: '' },
          isInspectionPay: { value: '', label: '' },
          isNoticeSalesman:{ value: '', label: '' },
          isNoticeReleaseCar: { value: '', label: '' },
          isDeliveryCard: { value: '', label: '' },
          refundFee: '',
          subscribeTime: '',
          // refunder: '',
          refunderMobile: '',
          productId: null,
          orderFund: {},
          orderSubject:'',
          orderTaxRate:'',
          orderSettlement: {},
          advanceCharge: '',
          deadline: undefined,
          startAddress: {},
          endAddress: {}
        },
        orderCustomer: {
          businessContactPerson: '',
          businessContactMobile: '',
          businessType: {},
          settlementMode: {}
        },
        orderVehicles: [
          {
            brands: [],
            brandName: '',
            vinNos: [],
            brandId: '',
            modelId: '',
            isCusPick: { value: null },
            isCusSend: { value: null },
            vehicleType: { value: 0, label: '' },
            insuranceCompany: { value: 1 },
            status: {},
            freight: '',
            insurancePremium: '',
            insuranceFee: '',
            isPlaceIn: { value: 0, label: '' },
            isTakeOut: { value: 0, label: '' },
            takeOutAddress: '',
            takeOutFee: undefined,
            placeInAddress: '',
            placeInFee: undefined,
            refundChecked: false,
            placeInChecked: false,
            takeOutChecked: false
          }
        ],
        startAddress: [],
        endAddress: []
      },
      rules: {
        order: {
          carrierType: { label: { required: true, message: '请选择承运公司', trigger: 'change', type: 'string' } },
          sourceMan: { required: true, message: '请选择分单员', trigger: 'blur' },
          consignor: {
            name: { required: true, message: '请填写交车人姓名', trigger: 'blur' },
            certificateNumber: { pattern: idNumberValidate, message: '请填写正确的身份证号', trigger: 'blur' },
            mobile: [
              { required: true, message: '请填写交车人联系方式', trigger: 'blur' },
              { pattern: mobileValidate, message: '请填写正确的联系方式', trigger: 'blur' }
            ]
          },
          picker: {
            name: { required: true, message: '请填写取车人姓名', trigger: 'blur' },
            certificateNumber: { pattern: idNumberValidate, message: '请填写正确的身份证号', trigger: 'blur' },
            mobile: [
              { required: true, message: '请填写取车人联系方式', trigger: 'blur' },
              { pattern: mobileValidate, message: '请填写正确的联系方式', trigger: 'blur' }
            ]
          },
          advanceCharge: [{ required: true, message: '请填写预付款', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
          deadline: [{ required: true, message: '请填写记账期限', trigger: 'blur', type: 'number' }, { min: 1, max: 15, message: '记账期限必须在2-15天之间', trigger: 'blur', type: 'number' }],
          refundFee: [{ required: true, message: '请填写返款金额', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
          peerCollectionFee: [{ required: true, message: '请填写代收金额', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
          orderFund: { value: { required: true, message: '请选择', trigger: 'change', type: 'number' } },
          orderSubject:[{ required: true, message: '请选择', trigger: 'change', type: 'number' }],
          orderTaxRate:[{ required: true, message: '请选择', trigger: 'change', type: 'number' }],
          taxRate:{value:{ required: true, message: '请选择', trigger: 'change', type: 'string' }},
          officeOrgId: { required: true, message: '请选择', trigger: 'change' },
          aging: [{ required: true, message: '请填写时效天数', trigger: 'blur' }, { pattern: positiveNumberValidate, message: '请填写大于0的正数', trigger: 'blur' }]
        },
        startAddress: { required: true, message: '请选择', trigger: 'change', type: 'array' },
        endAddress: { required: true, message: '请选择', trigger: 'change', type: 'array' }

      },
      chooseCustomerVisible: false
    })

    const batchGiveData = () => {
      let modData = JSON.stringify(data.form.orderVehicles[0])
      data.form.orderVehicles.forEach(item => {
        for (const key in item) {
          if (key === 'orderVehicleId' || key === 'vinNo') continue
          if (item[key].label) {
            item[key].value = JSON.parse(modData)[key].value
            item[key].label = JSON.parse(modData)[key].label
          } else if (!item[key].label) item[key] = JSON.parse(modData)[key]
        }
      })
    }

    const vinNoRepeat = (rule, value) => {
      let num = 0
      data.form.orderVehicles.forEach(item => {
        if (value === item.vinNo) {
          num++
        }
      })
      if (num > 1) {
        return Promise.reject(rule.message)
      }
      return Promise.resolve()
    }

    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    const handleChange = e => {
      data.form.pickType = e
    }
    const getCooperData = () => {
      getCooper(data.cooperCustomerId)
        .then(res => {
          if (res.code === 10000) {
            data.empList = res.data.empList
            data.receiverList = res.data.receiverList
          }
        }).catch(err => { console.log(err) })
    }

    onMounted(() => {
      productList({}).then(res => {
        data.products = res.data
      })
      carBrandTree({}).then(res => {
        data.carBrands = res.data
      })
      orgList({ orgType: 1 }).then(res => {
        data.orgs = res.data
      })
      data.type = router.currentRoute.value.params.type
      detail(router.currentRoute.value.params.id, {}).then(async res => {
        // console.log(res)
        reqGetSubjectList(0,res.data.order.invoiceCarrier)
        if (!res.data.order.isInspectionPay) res.data.order.isInspectionPay = { value: '', label: '' }
        if(!res.data.order.isNoticeSalesman) res.data.order.isNoticeSalesman = { value: '', label: '' }
        if (!res.data.order.isNoticeReleaseCar) res.data.order.isNoticeReleaseCar = { value: '', label: '' }
        if (!res.data.order.isDeliveryCard) res.data.order.isDeliveryCard = { value: '', label: '' }
        if (!res.data.order.taxRate||res.data.order.taxRate == 0){
          res.data.order.taxRate = { value: '', label: '' }
        } else{
          res.data.order.taxRate = { value: parseInt( res.data.order.taxRate) + '%' + '【'+res.data.order.taxItem + '】' , label:'' }
        }
        if(!res.data.order.invoiceCarrier){
          res.data.order.carrierType.label = null
        }else{
          res.data.order.carrierType.label = res.data.order.invoiceCarrier
        }
        taxItem.value = res.data.order.taxItem
        data.cooperCustomerId = res.data.orderCustomer.customerId
        getCooperData()
        data.form = {
          ...res.data,
          startAddress: [
            res.data.order.startAddress.province,
            res.data.order.startAddress.city,
            res.data.order.startAddress.area
          ],
          endAddress: [
            res.data.order.endAddress.province,
            res.data.order.endAddress.city,
            res.data.order.endAddress.area
          ]
        }
        data.insideName = data.form.order.isInside.label
        data.orderVehiclesCouponAmtSum = 0
        getSendAddress()
        getTakeAddress()
        await data.form.orderVehicles.forEach(item => {
          if (item.couponAmt !== null && Number(item.couponAmt) > 0) {
            data.orderVehiclesCouponAmtSum = Number(data.orderVehiclesCouponAmtSum) + Number(item.couponAmt)
          }
          item.brands = [item.brandId, item.modelId]
          if (item.isPlaceIn === null) item.isPlaceIn = {}
          if (item.pickType === null) item.pickType = {}
          if (item.takeType === null) item.takeType = {}
          if (item.isCusPick === null) item.isCusPick = { value: null }
          if (item.vehicleType === null) item.vehicleType = { value: null }
          if (item.insuranceCompany === null) item.insuranceCompany = {}
          if (item.isTakeOut === null) item.isTakeOut = { value: 0, label: '否' }
          if (item.isPlaceIn !== null) {
            data.pickTypes.push(item.isPlaceIn)
            if (item.isPlaceIn.value === 1) data.switchStatus = true
          }
          for (const key in item) {
            if (item[key] === null) {
              item[key] = ''
              if (key === 'status') item[key] = {}
            }
          }
          // console.log(item)
        })
      })
    })
    const dispathData = (item) => {
      data.cooperCustomerId = item.id
      data.form.orderCustomer = {
        customerId: item.id,
        customerName: item.name,
        settlementMode: item.settlementMode,
        certificateNumber: item.certificateNumber,
        customerMobile: item.mobile
      }

      data.form.order.refunder = item.receiver
      data.form.order.refunderMobile = item.receiverMobile
      getCooperData()
    }
    const chooseCustomer = item => {
      if (data.form.order.consignor?.name || data.form.order.consignor?.mobile) {
        Modal.confirm({
          title: () => '提示',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => '是否清除之前交车人信息?',
          onOk () {
            data.form.order.consignor = {
              name: item.receiver,
              certificateNumber: item.receiverCertificateNumber,
              mobile: item.receiverMobile
            }
            dispathData(item)
          },
          onCancel () {
            dispathData(item)
          },
        })
      } else {
        data.form.order.consignor = {
          name: item.receiver,
          certificateNumber: item.receiverCertificateNumber,
          mobile: item.receiverMobile
        }
        dispathData(item)
      }
      if(data.form.order.orderSettlement?.value){
         if(data.form.order.orderSettlement.value == 4 && data.form.orderCustomer.settlementMode.value !== 2){
          data.form.order.orderSettlement.value = null
         }
      }
    }

    const setStartAddress = (value, selectedOptions) => {
      data.form.order.startAddress.province = value[0]
      data.form.order.startAddress.city = value[1]
      data.form.order.startAddress.area = value[2]

      switch (value.length) {
        case 0:
          data.form.order.officeOrgId = ''
          break
        case 1:
          cityOrg({ provinceId: value[0], city: 0 }).then(res => {
            if (res.code === 10000) {
              data.form.order.officeOrgId = res.data.orgId
            }
          })
          break
        default:
          cityOrg({ provinceId: value[0], city: value[1] }).then(res => {
            if (res.code === 10000) {
              data.form.order.officeOrgId = res.data.orgId
            }
          })
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      data.form.order.endAddress.province = value[0]
      data.form.order.endAddress.city = value[1]
      data.form.order.endAddress.area = value[2]
    }
     //获取主体列表
     const reqGetSubjectList = (id,name) => {
      getSubjectList(id).then( res => {
        if(id == 0){
          res.data.forEach( item => {
          item.label = item.subjectName
          if(item.subjectName == name){
            reqGetSubjectList(item.id)
          }
          })
          carrierList.value = res.data
        }else{
          res.data.forEach( item => {
          item.label = item.subjectName + `【${item.taxItem}】`
          })
          taxRateList.value = res.data
        }
      })
    }
    const carrierChange = (e, v) => {
      reqGetSubjectList(v.key)
      data.form.order.carrierType = { label: v.children[0].children, value: v.key }
      data.form.order.taxRate.value = '' 
      taxItem.value = ''
    }
    const copyVehicle = index => {
      data.form.orderVehicles.splice(index + 1, 0, cloneDeep(data.form.orderVehicles[index]))
      data.form.orderVehicles[index + 1].orderVehicleId = ''
      data.form.orderVehicles[index + 1].vinNo = ''
    }

    const copyConsignor = (num) => {
      if (num === 1) data.form.order.picker = { ...data.form.order.consignor }
      if (num === 0) {
        data.form.order.consignor.mobile = data.form.orderCustomer.customerMobile
        data.form.order.consignor.name = data.form.orderCustomer.customerName
        data.form.order.consignor.certificateNumber = data.form.orderCustomer.certificateNumber
      }
      formRef.value.validate()
    }

    const addVehicle = () => {
      data.form.orderVehicles.push({
        brands: [],
        brandName: '',
        vinNos: [],
        brandId: '',
        modelId: '',
        insuranceCompany: { value: 1 },
        status: {},
        freight: '',
        insurancePremium: '',
        insuranceFee: '',
        isRefund: { value: 0, label: '' },
        refundFee: '',
        refunderMobile: '',
        isPlaceIn: { value: 0, label: '' },
        isTakeOut: { value: 0, label: '' },
        takeOutAddress: '',
        takeOutFee: 0,
        placeInAddress: 0,
        placeInFee: 0
      })
    }

    const deleteVehicle = index => {
      data.form.orderVehicles.splice(index, 1)
    }

    const save = () => {
      const status = data.form.orderVehicles.map(item => {
        if (item.vinNo.indexOf('虚') === 0) return item.vinNo.indexOf('虚')
        if (item.isTakeOut.value === 0) item.takeType = {}
        if (item.isPlaceIn.value === 0) item.pickType = {}
      })
      // console.log(status.includes(0))
      if (!status.includes(0)) {
        formRef.value.validate().then(() => {
          if (!data.form.orderCustomer.customerId) {
            Modal.warning({
              content: '请选择客户'
            })
          } else {
            let  msgInfo = JSON.parse(JSON.stringify(data.form)) 
                  msgInfo.order.invoiceCarrier =  msgInfo.order.carrierType.label
                  let str = null
                  taxRateList.value.forEach( item => {
                    if(item.label === msgInfo.order.taxRate.value){
                      str = item.taxRate
                    }
                  })
                  msgInfo.order.taxRate = str
                  msgInfo.order.taxItem = taxItem.value
            // 表示推送后修改
            if (data.type === '1') {
              pushEdit(msgInfo ).then(res => {
                if (res.code === 10000) {
                  message.success(res.msg)
                  const currentRouteName = router.currentRoute.value.name
                  router.replace({ name: router.currentRoute.value.query.backRoute }).then(() => {
                    store.commit('CLOSE_TAB', currentRouteName)
                  })
                  router.back()
                }
              }).catch(err => {
                console.log(err)
              }).finally(() => {
                data.subLoading = false
              })
            } else {
              const status = data.form.orderVehicles.every(item => item.brands[0] !== null)
              if (status) {
                if (data.form.order.isRefund && data.form.order.isRefund.value === 1) {
                  if (data.form.order.refunderMobile !== '' && data.form.order.refunder !== '' && data.form.order.refunderMobile !== null && data.form.order.refunder !== null) {
                    data.subLoading = true
                    appletModify(msgInfo).then(res => {
                      if (res.code === 10000) {
                        message.success(res.msg)
                        const currentRouteName = router.currentRoute.value.name
                        router.replace({ name: router.currentRoute.value.query.backRoute }).then(() => {
                          store.commit('CLOSE_TAB', currentRouteName)
                        })
                        router.back()
                      }
                    }).catch(err => {
                      console.log(err)
                    }).finally(() => {
                      data.subLoading = false
                    })
                  } else {
                    message.error('请选择返款人')
                  }
                } else {
                  data.subLoading = true
                  appletModify(msgInfo).then(res => {
                    if (res.code === 10000) {
                      message.success(res.msg)
                      const currentRouteName = router.currentRoute.value.name
                      router.replace({ name: router.currentRoute.value.query.backRoute }).then(() => {
                        store.commit('CLOSE_TAB', currentRouteName)
                      })
                      router.back()
                    }
                  }).catch(err => {
                    console.log(err)
                  }).finally(() => {
                    data.subLoading = false
                  })
                }
              } else {
                message.error('请检查车型未填完整')
              }
            }
          }
        })
      } else message.error('存在虚拟车架号,请先查看图片或修改')
    }
    const sourceManChange = (e, v) => {
      data.form.order.sourceMan = v.value
      data.form.order.sourceManId = v.key
    }
    const taxChange = (e,v) => {
      data.form.order.taxRate = { label: v.children[0].children, value: e }
      taxItem.value = v.lable
    }
    const refunderChange = (e, v) => {
      if (v !== undefined && v !== null) data.form.order.refunderMobile = v.value
      else data.form.order.refunderMobile = ''
      if (v !== undefined && v !== null) data.form.order.refunder = v.key
      else data.form.order.refunder = ''
    }
    const getSendAddress = () => { // 获取承运商地址库  客户送车地址--起始地搜索
      carrierAddress({
        isStore: 1,
        addressProvince: data.form.order.startAddress.province,
        addressCity: data.form.order.startAddress.city
      }).then(res => {
        let arr = []
        res.data.forEach(item => {
          item.address.forEach((v, i) => {
            arr.push({ name: item.name + '-' + v, carrierId: item.carrierId })
          })
        })
        setTimeout(() => {
          data.pickAddressList = arr
        }, 100)
      })
    }
    const getTakeAddress = () => { // 获取承运商地址库  客户取车门店--目的地搜索
      carrierAddress({
        isStore: 1,
        addressProvince: data.form.order.endAddress.province,
        addressCity: data.form.order.endAddress.city
      }).then(res => {
        let arr = []
        res.data.forEach(item => {
          item.address.forEach((v, i) => {
            arr.push({ name: item.name + '-' + v, carrierId: item.carrierId })
          })
        })
        setTimeout(() => {
          data.takeAddressList = arr
        }, 1000)
      })
    }
    const pickChange = (e, v) => {
      if (e === 0) data.isPlaceInCheck = []
      else data.isPlaceInCheck = [{ required: data.switchStatus, message: '请填写取车费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]
    }
    const takeChange = (e, v) => {
      if (e === 0) data.isTakeOutCheck = []
      else data.isTakeOutCheck = [{ required: true, message: '请填写送车费', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]
    }
    // 是否验车打款
    const isInspectionPayChange = (e, v) => {
      if (e.target.value === 1) data.form.order.isInspectionPay = { label: '是', value: 1 }
      else data.form.order.isInspectionPay = { label: '否', value: 0 }
    }
    // 是否等通知放车
    const isNoticeReleaseCarChange = (e, v) => {
      if (e.target.value === 1) data.form.order.isNoticeReleaseCar = { label: '是', value: 1 }
      else data.form.order.isNoticeReleaseCar = { label: '否', value: 0 }
    }
    // 预约取车时间选择
    const reservationTime = (e, v) => {
      data.form.orderVehicles[data.indexNum].pickAppointTime = v
      data.form.order.subscribeTime = v
    }
    // 是否到达门店
    const isCusPickChange = item => { // 客户取车门店
      if (item.isCusPick.value === 1) getTakeAddress()
    }
    const isCusSendChange = item => { // 客户送车门店
      if (item.isCusSend.value === 1) getSendAddress()
    }
    const chooseContact = item => {
      data.form.orderCustomer.businessContactPerson = item.receiver
      data.form.orderCustomer.businessContactMobile = item.receiverMobile
    }
    return {
      formRef,
      pickChange,
      ...toRefs(data),
      takeChange,
      vinNoRepeat,
      sourceManChange,
      refunderChange,
      filter,
      chooseContact,
      isCusPickChange,
      isCusSendChange,
      reservationTime,
      isInspectionPayChange,
      isNoticeReleaseCarChange,
      chooseCustomer,
      addVehicle,
      copyVehicle,
      copyConsignor,
      deleteVehicle,
      save,
      setStartAddress,
      setEndAddress,
      mobileValidate,
      moneyValidate,
      idNumberValidate,
      number,
      handleChange,
      batchGiveData,
      carrierList,
      taxRateList,
      taxChange,
      taxItem,
      carrierChange
    }
  },
  components: {
    // PlusOutlined,
    ChooseCustomer
  }
}
</script>
<style lang="less" scoped>
#components-back-top-custom .ant-back-top {
  bottom: 160px;
  right: 40px;
}

.batch-data {
  position: absolute;
  top: 10px;
  left: 1%;
  z-index: 10;
}

.table {
  width: 100%;
  font-size: 14px;

  td,
  th {
    text-align: center;
    padding: 5px 10px;
  }

  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    // border: 1px solid #c4dff5;
    border: 1px solid #000;
    max-width: 340px;
  }

  .left-td {
    text-align: left;
  }

  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .ant-form-item {
    margin: 0;
  }
}

.save-button {
  text-align: right;
}

.vehicle-div {
  background: #eee;
}
</style>
